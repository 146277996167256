var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.$can('update', 'agent'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mr-1",attrs:{"type":"button","variant":"primary","to":{ name: 'update-agent', params: { id: _vm.$route.params.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t("button.update"))+" ")],1):_vm._e(),(_vm.$can('create', 'agent'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"type":"button","variant":"primary","to":{ name: 'create-agent', query: { id: _vm.$route.params.id } }}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_vm._v(" "+_vm._s(_vm.$t("button.clone"))+" ")],1):_vm._e()],1)],1),_c('n-input-view',{attrs:{"fields":_vm.fields,"initValue":_vm.initData},scopedSlots:_vm._u([{key:"isVendor",fn:function(item){return [_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":_vm.$t(item.field.label),"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(item.field.type === 'checkbox')?_c('b-form-checkbox',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":item.field.key,"name":item.field.key,"value":true,"unchecked-value":false,"disabled":true},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}},[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}},{key:"vendorKey",fn:function(item){return [(_vm.data.isVendor)?_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":_vm.$t(item.field.label),"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.data[item.field.key]},on:{"click":function($event){return _vm.copy(_vm.data[item.field.key])}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1)]},proxy:true}],null,true)},[(item.field.type === 'text')?_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t(item.field.label),"name":item.field.key,"disabled":true,"autocomplete":"off"},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('span')]}},{key:"vendorSecret",fn:function(item){return [(_vm.data.isVendor)?_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":_vm.$t(item.field.label),"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.data[item.field.key]},on:{"click":function($event){return _vm.copy(_vm.data[item.field.key])}}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}})],1)]},proxy:true}],null,true)},[(item.field.type === 'text')?_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t(item.field.label),"name":item.field.key,"disabled":true,"autocomplete":"off"},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('span')]}},{key:"vendorBetWebhook",fn:function(item){return [(_vm.data.isVendor)?_c('b-form-group',{attrs:{"label-for":item.field.key}},[_c('validation-provider',{attrs:{"vid":item.field.key,"name":_vm.$t(item.field.label),"rules":item.field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" "+_vm._s(_vm.$t(item.field.label))+" ")]),(item.field.type === 'text')?_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t(item.field.label),"name":item.field.key,"disabled":true,"autocomplete":"off"},model:{value:(_vm.data[item.field.key]),callback:function ($$v) {_vm.$set(_vm.data, item.field.key, $$v)},expression:"data[item.field.key]"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('span')]}}]),model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}}),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("button.back"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }