<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="$can('update', 'agent')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1 mr-1"
            :to="{ name: 'update-agent', params: { id: $route.params.id } }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
          <b-button
            v-if="$can('create', 'agent')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1"
            :to="{ name: 'create-agent', query: { id: $route.params.id } }"
          >
            <feather-icon icon="CopyIcon" />
            {{ $t("button.clone") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view :fields="fields" v-model="data" :initValue="initData">
        <template #isVendor="item">
          <b-form-group :label-for="item.field.key">
            <validation-provider
              #default="{ errors }"
              :vid="item.field.key"
              :name="$t(item.field.label)"
              :rules="item.field.rules"
            >
              <b-form-checkbox
                v-if="item.field.type === 'checkbox'"
                :id="item.field.key"
                v-model="data[item.field.key]"
                :name="item.field.key"
                :value="true"
                :unchecked-value="false"
                :disabled="true"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                {{ $t(item.field.label) }}
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <template #vendorKey="item">
          <b-form-group :label-for="item.field.key" v-if="data.isVendor">
            <validation-provider
              #default="{ errors }"
              :vid="item.field.key"
              :name="$t(item.field.label)"
              :rules="item.field.rules"
            >
              <label>
                {{ $t(item.field.label) }}
              </label>
              <b-input-group>
                <b-form-input
                  v-if="item.field.type === 'text'"
                  v-model="data[item.field.key]"
                  :placeholder="$t(item.field.label)"
                  :name="item.field.key"
                  :disabled="true"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  autocomplete="off"
                />
                <template #append>
                  <b-button
                    variant="primary"
                    @click="copy(data[item.field.key])"
                    :disabled="!data[item.field.key]"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </template>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <span v-else></span>
        </template>
        <template #vendorSecret="item">
          <b-form-group :label-for="item.field.key" v-if="data.isVendor">
            <validation-provider
              #default="{ errors }"
              :vid="item.field.key"
              :name="$t(item.field.label)"
              :rules="item.field.rules"
            >
              <label>
                {{ $t(item.field.label) }}
              </label>
              <b-input-group>
                <b-form-input
                  v-if="item.field.type === 'text'"
                  v-model="data[item.field.key]"
                  :placeholder="$t(item.field.label)"
                  :name="item.field.key"
                  :disabled="true"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  autocomplete="off"
                />
                <template #append>
                  <b-button
                    variant="primary"
                    @click="copy(data[item.field.key])"
                    :disabled="!data[item.field.key]"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </template>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <span v-else></span>
        </template>
        <template #vendorBetWebhook="item">
          <b-form-group :label-for="item.field.key" v-if="data.isVendor">
            <validation-provider
              #default="{ errors }"
              :vid="item.field.key"
              :name="$t(item.field.label)"
              :rules="item.field.rules"
            >
              <label>
                {{ $t(item.field.label) }}
              </label>
              <b-form-input
                v-if="item.field.type === 'text'"
                v-model="data[item.field.key]"
                :placeholder="$t(item.field.label)"
                :name="item.field.key"
                :disabled="true"
                :class="errors.length > 0 ? 'is-invalid' : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <span v-else></span>
        </template>
      </n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BFormCheckbox,
  BInputGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonDelete from "@/components/NButtonDelete";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInputView from "@/components/NInputView";
import FormInput from "./formInput";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const AgentRepository = Repository.get("agent");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BInputGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: "male",
        username: null,
        email: null,
        password: null,
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      AgentRepository.show(this.$route.params.id).then((response) => {
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          };
        }
      });
    },
    deleteItem() {
      AgentRepository.delete(this.$route.params.id)
        .then((response) => {
          this.back();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
    copy(value) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(value);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("alert.copied"),
          icon: "BellIcon",
          text: this.$t("alert.copiedToClipboard"),
          variant: "info",
        },
      });
    },
  },
  setup() {
    const fields = [...FormInput];

    return { fields };
  },
};
</script>
